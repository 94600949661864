import { ResponsePromise } from 'ky'

export type IndexKey = string | number

export type TeamAssignment = {
  isAssigned?: boolean
  name?: string
  firstName: string
  lastName: string
  email: string
  type: 'Internal' | 'External'
  userId: string
  roles: {
    [role: string]: boolean
  }
  eidRole?: string
  eidRoleDescription?: string
  status?:string
  lastLogin?: string 
  rsmIsFederated: boolean
}

export interface ClientAssignment extends ClientUser {
  isAssigned?: boolean
}

export interface Index<T> {
  [id: string]: T | undefined
  [id: number]: T | undefined
}

export interface Entity {
  id: number
}

export interface AssignmentUser {
  userId: string
  isInternal: boolean
  firstName: string
  lastName: string
  email: string
  eidRole?: string
  eidRoleDescription?: string
  status?:string
  lastLogin?: string
  rsmIsFederated: boolean
}

export interface Assignment {
  roleCode: string
  user: AssignmentUser
}
export interface ErrorObject {
  validationErrors?: string[]
}

export interface ConfigurationSettings {
  ENV_RSMTSAPORTAL_URI: string
}

export type EngagementPhaseTypes =
  | 'setup'
  | 'clientVal'
  | 'pbc'
  | 'review'
  | 'cch'
  | 'irs'
  | 'closed'

export interface Engagement extends Entity {
  name: string
  assignments: Assignment[]
  engagementTaxForm: string
  engagementTemplateId: number
  favorited: boolean
  fundType?: string
  taxYear: string
  cchVersion: string | number
  clientId: string | number
  completionDate: string
  creationDate: string
  creator: string
  phase: EngagementPhaseTypes
  milestones?: EngagementMilestone[]
  client: Client
  priorYearReturnId?: string
  totalExpectedUnits?: number
  pdfProcessingMethod?: string
  ocrFolderName?: string
  rollForwardDate?: string
  template: EngagementTemplate
  is7216Eligible: boolean
  lastUpdatedDate?:string
  
}

export interface AllEngagementsResult extends Entity {
  data: { [key: string]: Engagement }
  errors: string
  isLoading: boolean
}

export interface OrgFilter {
  name: string
}

export interface UserProfile {
  id: string
  userID: string
  isInternal: boolean
  firstName: string
  lastName: string
  email: string
  sendEmail: string
  clientNames: string[]
  masterID: string
  clientID: string
  name: string
  roles?: string[]
}

export interface ClientInfo {
  name: string
  clientId: number
  masterId?: number
  displayId: string
  role: string
}

export interface EngagementMilestone {
  milestone: EngagementMileStoneCode
  dueDate?: string
  completeDate?: string
}

type EngagementMileStoneCode = 'setup' | 'pbc' | 'cch' | 'irs' | 'review'

export interface EngagementTemplate {
  type: string
  year?: number
  name: string
  integrateWithCch: boolean
  hasGlossary: boolean
}

export interface EngagementInfo {
  id: number
  name: string
  taxYear: number
  engagementTaxForm: string
  cchVersion: number
  phase: string
  status?: string
  roles: string[]
}

export interface FilterOption {
  label: string
  value: string
}

export interface ClientSearchResult {
  clientId: number
  masterId: number
  name: string
}

export interface ClientEngagements {
  clientId: number
  masterId: number
  name: string
  engagements: EngagementInfo[]
  role: string
  roleDescription: string
}

export interface ClientUser {
  email: string
  userId: string
  name: string
  firstName: string
  lastName: string
  type: 'Internal' | 'External'
  role: string
  roleDescription: string
  rsmIsFederated: boolean
  status: string
  lastLogin:string
  clients?: ClientInfo[]
}

export interface ClientUserMetadata {
  name?: string
  isInternal?: boolean
  email?: string
}

export interface ClientUserEngagements {
  clientUserMetadata: ClientUserMetadata
  clientEngagements: ClientEngagements[]
}

export interface ClientUserAssignmentUpdateRequest {
  userId: string
  operations: ClientUserAssignmentOperation[]
}

export interface ClientUserAssignmentOperation {
  uuid: string
  clientId: number
  engagementId: number
  roles: string[]
}

export interface ClientUserAssignmentResponse {
  // sucess: boolean
  // errorMessage: string
}

export interface UserRole {
  selected: boolean
  key: string
  label: string
}

export enum Milestone {
  Setup = 'setup',
  Pbc = 'pbc',
  Review = 'review',
  Cch = 'cch',
  Irs = 'irs',
}

export interface Client extends Entity {
  name?: string
  masterId?: number
}

export interface UserProfileModel {
  [key: string]: string | boolean | Date | undefined
  id: string
  rsmUid: string
  firstName: string
  lastName: string
  middleName?: string
  email: string
  title?: string
  lastLogin?: Date
  activated?: Date
  status: string
  rmsIsInternal: boolean
  rsmIsFederated: boolean
}
export interface UserSearchRequest {
  clientId?: string
  parentOu?: ParentOu | string
  filter?: string
}
export type ParentOu = 'Unknown' | 'External' | 'Internal'
export interface ProvisionUserRequestModel {
  email: string
  firstName: string
  lastName: string
  title?: string
  organizationId: string
  claims: string[]
}
export interface ProvisionUserResults{
  imported:number
  failed:number
  succeded:number
  errors: Dictionary<string>
}
export interface Dictionary<T> {
  [Key: string]: T
}
export interface ClientAccessRequest {
  id: number
  userId: string
  firstName: string
  lastName: string
  email: string
  createdDate?:string
  requestStatus: string
  clientId: number
  masterId: number
  approverUserId: string
  approverFirstName:string
  approverLastName:string
  approvedDate?: string
  notificationDate?: string
  notificationStatus: string
  approvedErrMessage: string
  notificationErrMessage: string
  tsaAuthorized: boolean
  twbAuthorized: boolean
  dmsAuthorized: boolean
  seviceNowIncidentNumber: string
  serviceNowIncidentSysId: string

}
/** Data grid cellRender properties */
export interface ICellData<T> {

  value?: any
  emails?: string[]
  rowType?: string
  row?: IRowData<T>
}

/** Data grid row data */
export interface IRowData<T> {
  data?: T
  isSelected?: boolean
  rowType?: string
}

