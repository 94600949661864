import {
  ClientAssignment,
  ClientSearchResult,
  ClientUser,
  ClientUserAssignmentResponse,
  ClientUserAssignmentUpdateRequest,
  ClientUserEngagements,
  ConfigurationSettings,
  Engagement,
  EngagementPhaseTypes,
  EngagementTemplate,
  ErrorObject,
  Index,
} from '../models'
import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { UserPermissionsState } from './reducers'

export const CLIENT_SEARCH_BEGIN = 'CLIENT_SEARCH_BEGIN'
export const CLIENT_SEARCH_END = 'CLIENT_SEARCH_END'
export const CLIENT_SEARCH_ERROR = 'CLIENT_SEARCH_ERROR'

export const USER_SEARCH_BEGIN = 'USER_SEARCH_BEGIN'
export const USER_SEARCH_END = 'USER_SEARCH_END'
export const USER_SEARCH_ERROR = 'USER_SEARCH_ERROR'

export const TOGGLE_FAVORITE_ENGAGEMENT_BEGIN =
  'TOGGLE_FAVORITE_ENGAGEMENT_BEGIN'
export const TOGGLE_FAVORITE_ENGAGEMENT_END = 'TOGGLE_FAVORITE_ENGAGEMENT_END'
export const TOGGLE_FAVORITE_ENGAGEMENT_ERROR =
  'TOGGLE_FAVORITE_ENGAGEMENT_ERROR'
export const TOGGLE_FAVORITE_ENGAGEMENT_REMOVE =
  'TOGGLE_FAVORITE_ENGAGEMENT_REMOVE'
export const SAVE_ERRORS = 'SAVE_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_STATUS = 'SET_STATUS'
export const SET_DUPLICATE = 'SET_DUPLICATE'

export const CLEAR_CLIENT_TEAM_ASSIGNMENTS = 'CLEAR_CLIENT_TEAM_ASSIGNMENTS'
export const GET_CLIENT_TEAM_ASSIGNMENTS_BEGIN =
  'GET_CLIENT_TEAM_ASSIGNMENTS_BEGIN'
export const GET_CLIENT_TEAM_ASSIGNMENTS_END = 'GET_CLIENT_TEAM_ASSIGNMENTS_END'

export const GET_CLIENT_USERS_BEGIN = 'GET_CLIENT_USERS_BEGIN'
export const GET_CLIENT_USERS_END = 'GET_CLIENT_USERS_END'
export const GET_CLIENT_USERS_ERROR = 'GET_CLIENT_USERS_ERROR'
export const CLEAR_CLIENT_USERS = 'CLEAR_CLIENT_USERS'
export const SET_CLIENT_ID = 'SET_CLIENT_ID'
export const SET_CLIENT_USER_STATUS = 'SET_CLIENT_USER_STATUS'

export const GET_CLIENT_USER_ENGAGEMENTS_BEGIN =
  'GET_CLIENT_USER_ENGAGEMENTS_BEGIN'
export const GET_CLIENT_USER_ENGAGEMENTS_END = 'GET_CLIENT_USER_ENGAGEMENTS_END'
export const GET_CLIENT_USER_ENGAGEMENTS_ERROR =
  'GET_CLIENT_USER_ENGAGEMENTS_ERROR'

export const POST_CLIENT_USER_ASSIGNMENTS_BEGIN =
  'POST_CLIENT_USER_ASSIGNMENTS_BEGIN'
export const POST_CLIENT_USER_ASSIGNMENTS_END =
  'POST_CLIENT_USER_ASSIGNMENTS_END'
export const POST_CLIENT_USER_ASSIGNMENTS_ERROR =
  'POST_CLIENT_USER_ASSIGNMENTS_ERROR'

export const GET_ALL_ENGAGEMENTS_BEGIN = 'GET_ALL_ENGAGEMENTS_BEGIN'
export const GET_ALL_ENGAGEMENTS_END = 'GET_ALL_ENGAGEMENTS_END'
export const GET_ENGAGEMENT_BEGIN = 'GET_ENGAGEMENT_BEGIN'
export const GET_ENGAGEMENT_END = 'GET_ENGAGEMENT_END'
export const GET_ENGAGEMENT_ERROR = 'GET_ENGAGEMENT_ERROR'
export const SAVE_ENGAGEMENT_BEGIN = 'SAVE_ENGAGEMENT_BEGIN'
export const SAVE_ENGAGEMENT_END = 'SAVE_ENGAGEMENT_END'
export const GET_ALL_ENGAGEMENTS_ERROR = 'GET_ALL_ENGAGEMENTS_ERROR'
export const SAVE_ENGAGEMENT_ERROR = 'SAVE_ENGAGEMENT_ERROR'

export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_PROFILES = 'GET_USER_PROFILES'
export const GET_ORGANIZATION_FILTERS = 'GET_ORGANIZATION_FILTERS'
export const GET_USER_MANAGEMENT_ERROR = 'GET_USER_MANAGEMENT_ERROR'
export const GET_CONFIGURATION_SETTINGS = 'GET_CONFIGURATION_SETTINGS'
export const GET_CONFIGURATION_SETTINGS_ERROR =
  'GET_CONFIGURATION_SETTINGS_ERROR'
export const GET_USER_PERMISSIONS_BEGIN = 'GET_USER_PERMISSIONS_BEGIN'
export const GET_USER_PERMISSIONS_END = 'GET_USER_PERMISSIONS_END'
export const GET_USER_PERMISSIONS_ERROR = 'GET_USER_PERMISSIONS_ERROR'

export const GET_TAX_FORMS_BEGIN = 'GET_TAX_FORMS_BEGIN'
export const GET_TAX_FORMS_END = 'GET_TAX_FORMS_END'

export const SOFT_DELETE_ENGAGEMENT_BEGIN = 'SOFT_DELETE_ENGAGEMENT_BEGIN'
export const SOFT_DELETE_ENGAGEMENT_END = 'SOFT_DELETE_ENGAGEMENT_END'
export const SOFT_DELETE_ENGAGEMENT_ERROR = 'SOFT_DELETE_ENGAGEMENT_ERROR'

export const GET_LAST_YEARS_DATA_BEGIN = 'GET_LAST_YEARS_DATA_BEGIN'
export const GET_LAST_YEARS_DATA_END = 'GET_LAST_YEARS_DATA_END'
export const GET_LAST_YEARS_DATA_ERROR = 'GET_LAST_YEARS_DATA_ERROR'

export const UPDATE_ENGAGEMENT_PHASE_BEGIN = 'UPDATE_ENGAGEMENT_PHASE_BEGIN'
export const UPDATE_ENGAGEMENT_PHASE_END = 'UPDATE_ENGAGEMENT_PHASE_END'
export const UPDATE_ENGAGEMENT_PHASE_ERROR = 'UPDATE_ENGAGEMENT_PHASE_ERROR'

// eslint-disable-next-line import/export
export const Actions = {
  getConfigurationSettings: (settings: ConfigurationSettings[]) =>
    createAction(GET_CONFIGURATION_SETTINGS, settings),
  getConfigurationSettingsError: (error: Error) =>
    createAction(GET_CONFIGURATION_SETTINGS_ERROR, error),

  clientSearchBegin: () => createAction(CLIENT_SEARCH_BEGIN),
  clientSearchEnd: (results: ClientSearchResult[]) =>
    createAction(CLIENT_SEARCH_END, results),
  clientSearchError: (error: Error) => createAction(CLIENT_SEARCH_ERROR, error),

  toggleFavoriteEngagementBegin: (engagementId: number | string) =>
    createAction(TOGGLE_FAVORITE_ENGAGEMENT_BEGIN, engagementId),
  toggleFavoriteEngagementEnd: (results: Engagement) =>
    createAction(TOGGLE_FAVORITE_ENGAGEMENT_END, results),
  toggleFavoriteEngagementError: (error: Error) =>
    createAction(TOGGLE_FAVORITE_ENGAGEMENT_ERROR, error),
  toggleFavoriteEngagementRemove: (engagementId: number | string) =>
    createAction(TOGGLE_FAVORITE_ENGAGEMENT_REMOVE, engagementId),

  clearClientTeamAssignments: () => createAction(CLEAR_CLIENT_TEAM_ASSIGNMENTS),
  getClientTeamAssignmentsBegin: () =>
    createAction(GET_CLIENT_TEAM_ASSIGNMENTS_BEGIN),
  getClientTeamAssignmentsEnd: (assignments: ClientAssignment[]) =>
    createAction(GET_CLIENT_TEAM_ASSIGNMENTS_END, assignments),

  getClientUsersBegin: () => createAction(GET_CLIENT_USERS_BEGIN),
  getClientUsersEnd: (results: ClientUser[]) =>
    createAction(GET_CLIENT_USERS_END, results),
  getClientUsersError: (error: Error) =>
    createAction(GET_CLIENT_USERS_ERROR, error),
  clearClientUsers: () => createAction(CLEAR_CLIENT_USERS),
  setClientIdEnd: (clientId: string) => createAction(SET_CLIENT_ID, clientId),
  setUserStatus: (results: ClientUser) =>
    createAction(SET_CLIENT_USER_STATUS, results),
  getClientUsersProfilesBegin: () => createAction(GET_CLIENT_USERS_BEGIN),
  getClientUsersProfilesEnd: (data: ClientUser[]) =>
    createAction(GET_CLIENT_USERS_END, data),
  getClientUsersProfilesError: (error: Error) =>
    createAction(GET_CLIENT_USERS_ERROR, error),

  getClientUserEngagementsBegin: () =>
    createAction(GET_CLIENT_USER_ENGAGEMENTS_BEGIN),
  getClientUserEngagementsEnd: (results: ClientUserEngagements) =>
    createAction(GET_CLIENT_USER_ENGAGEMENTS_END, results),
  getClientUserEngagementsError: (error: Error) =>
    createAction(GET_CLIENT_USER_ENGAGEMENTS_ERROR, error),

  postClientUserAssigmentsBegin: (request: ClientUserAssignmentUpdateRequest) =>
    createAction(POST_CLIENT_USER_ASSIGNMENTS_BEGIN),
  postClientUserAssigmentsEnd: (results: ClientUserAssignmentResponse) =>
    createAction(POST_CLIENT_USER_ASSIGNMENTS_END, results),
  postClientUserAssigmentsError: (error: Error) =>
    createAction(POST_CLIENT_USER_ASSIGNMENTS_ERROR, error),
  getAllEngagementsBegin: () => createAction(GET_ALL_ENGAGEMENTS_BEGIN),
  getAllEngagementsEnd: (data: Index<Engagement>) =>
    createAction(GET_ALL_ENGAGEMENTS_END, data),
  getEngagementBegin: () => createAction(GET_ENGAGEMENT_BEGIN),
  getEngagementEnd: (engagement: Engagement) =>
    createAction(GET_ENGAGEMENT_END, engagement),
  getAllEngagementsError: (error: Error) =>
    createAction(GET_ALL_ENGAGEMENTS_ERROR, error),
  getEngagementError: (error: Error) =>
    createAction(GET_ENGAGEMENT_ERROR, error),

  getUserInfo: (userInfo: any[]) => createAction(GET_USER_INFO, userInfo),
  getOrganizationFilters: (organizationFilters: string[]) =>
    createAction(GET_ORGANIZATION_FILTERS, organizationFilters),
  getUserProfiles: (userProfiles: any[]) =>
    createAction(GET_USER_PROFILES, userProfiles),
  getUserManagementError: (error: Error) =>
    createAction(GET_USER_MANAGEMENT_ERROR, error),

  getUserPermissionsBegin: () => createAction(GET_USER_PERMISSIONS_BEGIN),
  getUserPermissionsEnd: (userPermissions: UserPermissionsState) =>
    createAction(GET_USER_PERMISSIONS_END, userPermissions),
  getUserPermissionsError: (error: Error) =>
    createAction(GET_USER_PERMISSIONS_ERROR, error),

  saveEngagementBegin: () => createAction(SAVE_ENGAGEMENT_BEGIN),
  saveEngagementEnd: (engagement: Engagement) =>
    createAction(SAVE_ENGAGEMENT_END, engagement),
  saveEngagementError: (error: Error) =>
    createAction(SAVE_ENGAGEMENT_ERROR, error),

  saveErrors: (errors: ErrorObject) => createAction(SAVE_ERRORS, errors),
  setStatus: (status: Number) => createAction(SET_STATUS, status),
  clearErrors: () => createAction(CLEAR_ERRORS),
  setDuplicate: (duplicate: boolean) => createAction(SET_DUPLICATE, duplicate),

  getTaxFormsBegin: () => createAction(GET_TAX_FORMS_BEGIN),
  getTaxFormsEnd: (templates: EngagementTemplate[]) =>
    createAction(GET_TAX_FORMS_END, templates),

  softDeleteEngagementBegin: () => createAction(SOFT_DELETE_ENGAGEMENT_BEGIN),
  softDeleteEngagementEnd: () => createAction(SOFT_DELETE_ENGAGEMENT_END),
  softDeleteEngagementError: (error: Error) =>
    createAction(SOFT_DELETE_ENGAGEMENT_ERROR, error),

  getLastYearsDataBegin: () => createAction(GET_LAST_YEARS_DATA_BEGIN),
  getLastYearsDataEnd: () => createAction(GET_LAST_YEARS_DATA_END),
  getLastYearsDataError: (error: Error) =>
    createAction(GET_LAST_YEARS_DATA_ERROR, error),

  updateEngagementPhaseBegin: (
    engagementId: number | string,
    phase: EngagementPhaseTypes
  ) => createAction(UPDATE_ENGAGEMENT_PHASE_BEGIN, { engagementId, phase }),
  updateEngagementPhaseEnd: (
    engagementId: number | string,
    phase: EngagementPhaseTypes
  ) => createAction(UPDATE_ENGAGEMENT_PHASE_END, { engagementId, phase }),
  updateEngagementPhaseError: (error: Error) =>
    createAction(UPDATE_ENGAGEMENT_PHASE_ERROR, error),
}

// eslint-disable-next-line import/export
export type Actions = ActionsUnion<typeof Actions>
