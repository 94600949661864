import {
  createStyles,
  ProgressTabs,
  Theme,
  Formik,
  Button,
  Grid,
} from '@rsmus/react-ui'
import clsx from 'clsx'
import React, { createContext, useEffect, useState, useMemo } from 'react'
import {
  FaCheckCircle,
  FaTimesCircle,
  FaExclamationCircle,
  FaInfoCircle,
} from 'react-icons/fa'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'
import { AppDispatch, AppState, thunks, Actions } from '../../redux'
import ClientUserAssignment from '../ClientUserAssignment'
import EngagementForm from '../EngagementForm'
import RsmUserAssignment from '../RsmUserAssignment'
import { Modal } from '../Shared/Modal'
import { ConfirmModal } from '../Shared/ConfirmModal'
import { ConfirmationAlert } from '../Shared/ConfirmationAlert'

import { validate } from './validation'
import { ENGAGEMENT_PHASE_INFO, FormModel } from './models'
import { buildSubmitRequest, getInitialFormValues } from './formHelpers'
import { EngagementPhaseTypes, TeamAssignment } from '../../models'
import { updateEngagementPhase } from '../../redux/engagementThunks'

// Modify the useState function so it wraps the setter functions
//  in a delay.  Since these functions are used during the render of the
//  Formik component, this will allow the component to finish, and prevent errors.
function useDelayedState<T>(
  initValue: T,
  mountState: { isMounted: boolean }
): [T, (newVal: T) => void] {
  // Get the natural callback/state from react.
  const [newInit, setVal] = useState(initValue)

  // Create the wrapped setState function.
  const updateFunc = (newVal: T) => {
    setTimeout(() => {
      if (!mountState.isMounted) {
        return
      }
      setVal(newVal)
    }, 0)
  }

  // Return the new set.
  return [newInit, updateFunc]
}

const tabs = [
  {
    step: 'overview',
    title: 'Engagement Overview',
    subtitle: '',
  },
  {
    step: 'rsm-team',
    title: 'RSM Team Setup',
    subtitle: '',
  },
  {
    step: 'client-team',
    title: 'Client Team Setup',
    subtitle: '',
  }  
]

const styles = ({ palette, typogrphaphy }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    success: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.green.light}`,
    },
    warning: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.informational.yellow}`,
    },
    failure: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.informational.red}`,
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: palette.grey['200'],
    },
    content: {
      flex: '1 1 0',
      overflowY: 'auto',
      padding: '1rem 1.5rem',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: palette.grey['200'],
      padding: '0 1.5rem',
      height: '4.5rem',
    },
    // TODO: Lift buttons to react-ui (with icons too?)
    button: {
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    modalText: {
      fontSize: '1.25rem',
    },
    modalList: {
      textAlign: 'initial',
    },
    modalButton: {
      display: 'inline-block !important',
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      minWidth: '100px',
    },
    phaseModalContent: {
      fontSize: typogrphaphy.fontSize,
      '& button': {
        marginLeft: '10px',
      },
    },
    phaseModalMessage: {
      width: '100%',
      margin: '10px 0',
    },
    phaseSelect: {
      height: '32px',
      width: '100%',
      margin: '10px 0',
    },
    phaseSelectLabel: {
      margin: '10px auto',
      height: '32px',
      fontWeight: 'bolder',
    },
    phaseSelectContainer: {
      margin: '0 20px',
    },
    cancelButton: {
      backgroundColor: 'lightgray',
    },
  })

type RouteProps = RouteComponentProps<{
  id: string
  step?: string
  resourceType?: string
}>

type PhaseChangeStates = 'none' | 'updating' | 'error' | 'complete'

interface EngagementSceneOwnProps extends RouteProps {
  newEngagement?: boolean
}

const useStyles = createUseStyles(styles)

export type EngagementSceneProps = EngagementSceneOwnProps

const defaultContext = {
  handleClientIdChange: (clientId: string) => {},
}
export const EngagementFormContext = createContext(defaultContext)

const EngagementScene = ({
  history,
  newEngagement,
  match: { params, path },
}: EngagementSceneProps) => {
  const classes = useStyles()

  // Create a mounted state, which will be set by a following useEfect call during dismount.
  //  This state is checked before setting component state values, to prevent them being
  //  called after the component dismounts.
  const mountState = useMemo(() => ({ isMounted: true }), [])

  const isNewEngagement = newEngagement
  const isRollForward = path.indexOf('rollforward') !== -1
  const [isConfirmationShown, setIsConfirmationShown] = useDelayedState(
    false,
    mountState
  )
  const [showClientSelectionWarning, setShowClientSelectionWarning] = useDelayedState(false, mountState)  
  const [isClientAssignmentValidation, setIsClientAssignmentValidation] = useDelayedState(false, mountState)  
  const [isModalShown, setIsModalShown] = useDelayedState(false, mountState)
  const [isLoading, setIsLoading] = useDelayedState(false, mountState)
  const [shouldRedirect, setRedirect] = useDelayedState(false, mountState)
  const [isPhaseChangeModalOpen, setPhaseChangeModalOpen] = useDelayedState(
    false,
    mountState
  )
  // Set when the phase is being set on the server, and we're awaiting it to finish.
  const [phaseChangeState, setPhaseChangeState] = useDelayedState(
    'none' as PhaseChangeStates,
    mountState
  )
  // const [isDuplicateWarningShown, setIsDuplicateWarningShown] = useState(false)

  // When the component dismounts, we want to know about it so
  //  other functions don't try to change the components state.
  useEffect(() => {
    return () => {
      mountState.isMounted = false
    }
  }, [])

  const handleClientIdChange = (clientId: string) => {
    if (clientId.length && clientId.length <= 7) {
      const converted = parseInt(clientId, 10).toString()
      dispatch(thunks.clientUsers.getById(converted))
    } else {
      dispatch(thunks.clientUsers.clearUsers())
    }
  }

  const dispatch = useDispatch<AppDispatch>()

  const engagement = useSelector(
    (state: AppState) => state.engagements.data[params.id]
  )

  // Controls the phase that the user manually selects in the dialog.
  const [selectedPhase, setSelectedPhase] = useState(
    engagement?.phase ?? 'setup'
  )

  const errors: string[] | undefined = useSelector(
    (state: AppState) => state.validationErrors.errors
  )
  const status: Number | undefined = useSelector(
    (state: AppState) => state.validationErrors.status
  )
  const duplicate: boolean | undefined = useSelector(
    (state: AppState) => state.validationErrors.duplicate
  )
  const clientId = engagement && engagement.clientId

  useEffect(() => {
    setIsLoading(false)
  }, [errors, status])

  useEffect(() => {
    if (!isNewEngagement || isRollForward) {
      dispatch(thunks.engagement.getById(params.id))
      setIsLoading(true)
    }
    dispatch(thunks.engagement.getTaxForms())
    dispatch(Actions.clearErrors())
  }, [dispatch, isNewEngagement, isRollForward, params.id])

  useEffect(() => {
    if (clientId) {
      dispatch(thunks.clientUsers.getById(clientId.toString()))
    }
  }, [dispatch, clientId])

  useEffect(() => {
    // Update the selected phase from the engagement if it changes.
    if (engagement) {
      setSelectedPhase(engagement.phase)
    }
  }, [engagement])

  /** Called when the dialog to change the current phase manually is closed. */
  const onPhaseSelectClosed = async (accepted: boolean) => {
    if (engagement) {
      if (accepted) {
        // Indicate we're updating the phase on the server, so we can't try changing it again mid-process.
        setPhaseChangeState('updating')

        // Update the value on the engagement and on the server-side.
        await dispatch(updateEngagementPhase(engagement.id, selectedPhase!))

        // Done with the update!
        setPhaseChangeState('complete')
      } else {
        // If not accepted, reset the value.
        setSelectedPhase(engagement.phase)
        // Close the dialog - we're done.
        setPhaseChangeModalOpen(false)
      }
    }
  }

  // Determine the options that a user can transition the current phase to.
  //  Note that the current phase is included, since it won't cause a transition if they select
  //  the currently selected phase.
  const phaseOptions = useMemo(
    () =>
      ENGAGEMENT_PHASE_INFO.filter(
        (v) => v.canTransitionTo || v.value === engagement?.phase
      ).map((v) => ({
        label: v.label,
        value: v.value,
      })),
    []
  )

  // Ensure the name of the phase is set correctly.
  if (engagement) {
    const recast = engagement as FormModel
    recast.phaseName =
      ENGAGEMENT_PHASE_INFO.find((p) => p.value === engagement.phase)?.label ??
      'RSM Setup'
  }

  const tabManager = (formVals: any) => {
    const setTab = (index: number) => {
      const nextPath = generatePath(path, {
        id: params.id,
        step: tabs[index].step,
      })
      history.push(nextPath)
    }

    const step = (params.step && params.step.toLowerCase()) || tabs[0].step
    const activeTabIndex = tabs.findIndex((t) => t.step === step)

    if (activeTabIndex === -1) {
      setTab(0)
    }

    return {
      activeTabIndex,
      next: () => setTab(Math.min(tabs.length - 1, activeTabIndex + 1)),
      prev: () => setTab(Math.max(0, activeTabIndex - 1)),
      setTab,
      step,
      tabs: tabs,
    }
  }

  const primaryButtonClassName = clsx(classes.button, classes.buttonPrimary)

  const CancelButton = (props: any) => {
    return (
      <button
        className={classes.button}
        type='button'
        onClick={() => {
          if (props.dirty) {
            setIsConfirmationShown(true)
          } else {
            setRedirect(true)
          }
        }}
      >
        Cancel
      </button>
    )
  }

  const SaveButton = () => {
    return (
      <button type='submit' className={primaryButtonClassName}>
        Save & Exit
      </button>
    )
  }

  const ActiveContent = (props: any) => {
    switch (props.step) {
      case 'overview':
        return (
          <EngagementForm
            isRollForward={isRollForward}
            onChangePhaseClicked={() => {
              setPhaseChangeState('none')
              setPhaseChangeModalOpen(true)
            }}
          />
        )
      case 'rsm-team':
        return <RsmUserAssignment />
      case 'client-team':
        return <ClientUserAssignment />
      default:
        return <div>nothing to see here</div>
    }
  }

  const ResponseContent = (props: any) => {
    if (props.isLoading) {
      return <p>Saving...</p>
    } else {
      switch (status) {
        case 400:
          // Big red X, Please fix issues
          return (
            <div className={classes.modalText}>
              <p>
                <FaTimesCircle className={classes.failure} />
                <span>Please fix the following field errors:</span>
              </p>
              <ul className={classes.modalList}>
                {errors !== undefined &&
                  errors.map((err, index) => (
                    <li key={`err${index}`}>{err}</li>
                  ))}
              </ul>
            </div>
          )
        case 500:
          // Big red X, dunno what happened
          return (
            <div>
              <FaTimesCircle className={classes.failure} />
              <p>Unexpected error, please refresh and try again.</p>
            </div>
          )
        case 200:
          // Green check
          return (
            <div>
              <FaCheckCircle className={classes.success} />
              <p>Engagement Saved Successfully</p>
            </div>
          )
        default:
          return <p>No status message?</p>
      }
    }
  }

  // Content to show when the phase change operation completes successfully.
  const PhaseChangedSuccessfullyContent = (
    <div>
      <FaCheckCircle className={classes.success} />
      <p>Engagement Saved Successfully</p>

      <Button
        className={classes.modalButton}
        onClick={() => onPhaseSelectClosed(false)}
        type='button'
      >
        OK
      </Button>
    </div>
  )

  // Content to show when the phase change operation fails.
  const PhaseChangedFailedContent = (
    <div>
      <div>
        <FaTimesCircle className={classes.failure} />
        <p>Unexpected error, please refresh and try again.</p>
      </div>

      <Button
        className={classes.modalButton}
        onClick={() => onPhaseSelectClosed(false)}
        type='button'
      >
        OK
      </Button>
    </div>
  )

  // Content to show when the phase change operation is in progress.
  const PhaseChangeUpdatingContent = <p>Saving...</p>

  const ClientErrors = (props: any) => {
    return (
      <div className={classes.modalText}>
        <p>
          <FaTimesCircle className={classes.failure} />
          <span>Please fix the following field errors:</span>
        </p>
        <ul className={classes.modalList}>
          {Object.keys(props.errors) &&
            Object.keys(props.errors).map((err: string, index: number) => (
              <li key={`err${index}`}>{props.errors[err]}</li>
            ))}
        </ul>
      </div>
    )
  }

  const handleConfirmationClose = () => {
    setIsConfirmationShown(false)
  }

  const handleDuplicateClose = () => {
    dispatch(Actions.setDuplicate(false))
    setIsLoading(false)
    setIsModalShown(false)
  }

  const handleClientSelectionWarningClose = () => {
    setShowClientSelectionWarning(false)
    setIsModalShown(false)
  }

  const handleClientSelectionWarningProceed = () => {
    setIsClientAssignmentValidation(true)
    setShowClientSelectionWarning(false)
    setIsModalShown(false)
  }

  const handleRedirect = () => {
    setRedirect(true)
  }

  const handleModalClose = () => {
    setRedirect(status === 200)
    setIsModalShown(false)
  }
  const handleDuplicateSave = () => {
    dispatch(Actions.setDuplicate(false))
    // setIsModalShown(false)
    if (isRollForward) {
      dispatch(
        thunks.engagement.saveEngagement(lastRequest.current, true, engagement)
      )
    } else {
      dispatch(thunks.engagement.saveEngagement(lastRequest.current))
    }
    setIsLoading(true)
  }
  const lastRequest = React.useRef(null) // save for the save after confirm duplicates
  const handleSubmit = (v: FormModel) => {
    const isClientAssigned = v.clientTeamAssignments.some((client: TeamAssignment) => client.isAssigned)

    if(isClientAssigned || isClientAssignmentValidation){
      if(showClientSelectionWarning){
        setShowClientSelectionWarning(false)  
      }
      if(!isClientAssignmentValidation){
        setIsClientAssignmentValidation(true)
      }
      const request = buildSubmitRequest(v)
      lastRequest.current = request

      if (isRollForward) {
        dispatch(
          thunks.engagement.checkDuplicateEngagementAndSave(
            request,
            true,
            engagement
          )
        )
      } else {
        dispatch(thunks.engagement.checkDuplicateEngagementAndSave(request))
      }
      setIsLoading(true)
    }else{
      setShowClientSelectionWarning(true)
      setIsClientAssignmentValidation(false)
    }
  }

  const formContextData = { handleClientIdChange }

  if ((!isNewEngagement || isRollForward) && !engagement) {
    return null
  } else if (!isModalShown && isLoading) {
    return <div>Loading engagement...</div>
  } else if (shouldRedirect) {
    return <Redirect to='/engagements' />
  } else {
    return (
      <EngagementFormContext.Provider value={formContextData}>
        <Formik
          initialValues={getInitialFormValues(engagement, isRollForward)}
          validate={validate}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ dirty, errors, handleSubmit, isValidating, values }) => {
            const { activeTabIndex, next, prev, setTab, step, tabs } =
              tabManager(values)

            if (isValidating) {
              setIsModalShown(true)
            }

            return (
              <form onSubmit={handleSubmit} className={classes.root}>
                <ConfirmModal
                  shown={isConfirmationShown}
                  onCancel={handleConfirmationClose}
                  onConfirm={handleRedirect}
                >
                  Are you sure you want to leave this page? Your data will not
                  be saved.
                </ConfirmModal>
                <ConfirmModal
                  shown={showClientSelectionWarning}
                  onCancel={handleClientSelectionWarningClose}
                  onConfirm={handleClientSelectionWarningProceed}
                >
                  Please select a user to proceed.
                </ConfirmModal>
                <ConfirmationAlert
                  shown={!!duplicate}
                  onDismiss={handleDuplicateClose}
                  buttons={[
                    {
                      label: 'CANCEL',
                      variant: 'standard',
                      action: handleDuplicateClose,
                    },
                    {
                      label: 'YES',
                      variant: 'primary',
                      action: handleDuplicateSave,
                    },
                  ]}
                >
                  <FaExclamationCircle className={classes.warning} />
                  <h3>Potential Duplicate Engagement</h3>
                  <h6>
                    An engagement already exists with the specified client, tax
                    form, tax year and CCH version.
                  </h6>
                  <h6>Proceed?</h6>
                </ConfirmationAlert>                
                <Modal
                  shown={isModalShown && !duplicate && !showClientSelectionWarning}
                  onClose={handleModalClose}
                >
                  {isLoading || status !== -1 ? (
                    <ResponseContent isLoading={isLoading} />
                  ) : (
                    <ClientErrors errors={errors} />
                  )}
                  <Button
                    className={classes.modalButton}
                    onClick={handleModalClose}
                    style={isLoading ? { display: 'none' } : undefined}
                  >
                    OK
                  </Button>
                </Modal>

                <Modal
                  shown={isPhaseChangeModalOpen}
                  onClose={() => onPhaseSelectClosed(false)}
                >
                  {phaseChangeState === 'error' && PhaseChangedFailedContent}
                  {phaseChangeState === 'updating' &&
                    PhaseChangeUpdatingContent}
                  {phaseChangeState === 'complete' &&
                    PhaseChangedSuccessfullyContent}
                  {phaseChangeState === 'none' && (
                    <Grid
                      container
                      xs='12'
                      className={classes.phaseModalContent}
                    >
                      <FaExclamationCircle className={classes.warning} />
                      <Grid item xs='12' className={classes.modalText}>
                        <h3>Change Current Phase</h3>
                      </Grid>
                      <Grid item xs='12' className={classes.phaseModalMessage}>
                        <h6>
                          The engagement phase will be updated. Reverting to
                          earlier phases can open the engagement for client
                          updates.
                        </h6>
                      </Grid>
                      <Grid container xs='12'>
                        <Grid item xs='4'>
                          <label className={classes.phaseSelectLabel}>
                            New Phase:
                          </label>
                        </Grid>
                        <Grid container xs='8'>
                          <Grid
                            item
                            xs='12'
                            className={classes.phaseSelectContainer}
                          >
                            <select
                              onChange={(e) =>
                                setSelectedPhase(
                                  e.target.value as EngagementPhaseTypes
                                )
                              }
                              value={selectedPhase}
                              className={classes.phaseSelect}
                            >
                              {phaseOptions.map((p) => (
                                <option key={p.value} value={p.value}>
                                  {p.label}
                                </option>
                              ))}
                            </select>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs='12'>
                        <Button
                          className={[
                            classes.modalButton,
                            classes.cancelButton,
                          ].join(' ')}
                          onClick={() => onPhaseSelectClosed(false)}
                          type='button'
                        >
                          CANCEL
                        </Button>
                        <Button
                          className={classes.modalButton}
                          onClick={() => onPhaseSelectClosed(true)}
                          type='button'
                        >
                          OK
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Modal>
                <div className={classes.tabs}>
                  <ProgressTabs
                    tabs={tabs}
                    activeTabIndex={activeTabIndex}
                    onClick={setTab}
                  />
                  <div>
                    <CancelButton dirty={dirty} />
                    <SaveButton />
                  </div>
                </div>
                <div className={classes.content}>
                  {engagement && engagement.name}
                  <ActiveContent step={step} />
                </div>
                <div className={classes.footer}>
                  <button
                    className={primaryButtonClassName}
                    type={'button'}
                    disabled={activeTabIndex <= 0}
                    onClick={prev}
                  >
                    {'< Prev'}
                  </button>
                  <button
                    className={primaryButtonClassName}
                    type={'button'}
                    disabled={activeTabIndex === tabs.length - 1}
                    onClick={next}
                  >
                    {'Next >'}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </EngagementFormContext.Provider>
    )
  }
}

// TODO: Properly split these into two components (edit and new)
const StyledEngagementScene = EngagementScene

export const NewEngagementScene = (props: EngagementSceneProps) => (
  <StyledEngagementScene {...props} newEngagement />
)

export default StyledEngagementScene
